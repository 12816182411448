/**
 * Banner provides a container with a title, a button, and an image.
 * Supported props: title subtitle startstudy banner
 */
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Safe from './Safe';

export default function Banner(props) {
  const cfg = props.src;
  const rootStyle = 'row banner' + (cfg.banner.theme ? ` ${cfg.banner.theme}` : '');
  const btnStyle = 'get-started-btn' + (cfg.banner.btntheme ? ` ${cfg.banner.btntheme}` : '');
  return (
    <div id="banner" className={rootStyle}>
      <div className="col-xs-12 col-sm-6">
        <div className="banner-left-content">
          <h1 className="banner-title">{cfg.title}</h1>
          <Safe className="banner-text" content={cfg.subtitle} />
          <div className="my-8">
            <Link to="#screener" className={btnStyle}>{cfg.startstudy}</Link>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 banner-img-container">
        <StaticImage src='../images/banner.png' alt="main banner" layout="fullWidth" className="hidden-xs" />
        <StaticImage src='../images/banner_mobile.jpg' alt="main banner" layout="fullWidth" className="visible-xs" />
      </div>
    </div>
  );
};
