/**
 * A Slide is a container for a Screener question or a conclusion page.
 */
import React, { useEffect } from 'react';
import pagetools from '../util/pagetools';

export default function Slide(props) {
  useEffect(() => {
    if (props.isActive) pagetools.adjustSlideHeight();
  });

  if (props.hasOwnProperty('question')) return renderQuestion(props);
  return renderPage(props);
}

/**
 * Render a screener question.
 * @param object
 * @return object
 */
function renderQuestion(props) {
  const yesHandler = props.responder(props.idx, true);
  const noHandler = props.responder(props.idx, false);
  const slideBg = (props.idx%2===0 ? 'bg-pary' : 'bg-pary-light');
  const slideStyle = getStyleSlideIn(props.isActive, props.isPast) + ` ${(props.question.theme || slideBg)}`;
  const qs = props.question;

  return (
    <div className={slideStyle}>
      <div className="slide-counter">{props.qLabel}</div>
      <div className="slide-h1-mb slide-question-text">{qs.q}</div>
      <div className="row">
        <div className="col-lg-3 col-sm-4 col-xs-6"><button onClick={yesHandler} className="slide-btn btn btn-block">{props.btnLabels.affirm}</button></div>
        <div className="col-lg-3 col-sm-4 col-xs-6"><button onClick={noHandler} className="slide-btn btn btn-block">{props.btnLabels.deny}</button></div>
        {qs.altBtn &&
          <div className="col-lg-4 col-sm-6 col-xs-12"><button onClick={yesHandler} className={"slide-btn btn btn-block"}>{qs.altBtn}</button></div>
        }
      </div>
    </div>
  );
}

/**
 * Render a conclusion page.
 * @param boolean - the active status
 * @param object - child elements
 * @return object
 */
function renderPage(props) {
  const slideStyle = getStyleSlideIn(props.isActive, props.isPast) + ` ${props.theme || 'bg-default-slide'}`;
  return <div className={slideStyle}>{props.children}</div>
}

/**
 * Define styling for root element using a slide-in transition.
 * @param boolean - is this slide the active slide
 * @param boolean - has this slide already been visited
 * @return string
 */
function getStyleSlideIn(isActive, isPast) {
  const xPos = (isActive ? 'slide-active' : (isPast ? 'slide-left' : 'slide-right'));
  return 'slide '+xPos;
}
